// Import React's Components
import React, { createContext, useEffect, useState } from "react";
import apiWrapper from "../Index/api";

// Import Context
import CreateContext from "./CreateContext";

// GET TOKEN
const access_token = sessionStorage.getItem("auth-token");

// console.log(access_token);

// REDUCERS
const dataWrapperReducer = (state, action) => {
  if ((action.type = "getData")) {
    // console.log("payload :", action.payload);

    return {
      ...state,
      data: action.payload,
    };
  }
};

// GET DATA MASTER
const getDataMaster =
  (dispatch) =>
  async ({ dataMaster, onAwait, onSuccess, onReject }) => {
    let query = dataMaster;
    if (onAwait) onAwait();

    apiWrapper
      .get("api/v1/" + query, {
        headers: { "x-access-token": access_token },
      })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
        // console.log("On Success ====> ");
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET DATA
const getDataTable =
  (dispatch) =>
  async ({ dataTable, deviceID, onAwait, onSuccess, onReject }) => {
    let query = dataTable + "/" + deviceID;
    if (onAwait) onAwait(deviceID);

    apiWrapper
      .get("api/v1/" + query, {
        headers: { "x-access-token": access_token },
      })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
        // console.log("On Success ====> ");
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET DATA HISTORY
const getDataHistory =
  (dispatch) =>
  async ({ deviceID, shift, dateTime, onAwait, onSuccess, onReject }) => {
    let query = "/" + deviceID + "/" + shift + "/" + dateTime;
    if (onAwait) onAwait();

    apiWrapper
      .get("api/v1/" + "/history" + query, {
        headers: { "x-access-token": access_token },
      })
      .then((response) => {
        dispatch({ type: "getData", payload: response.history });
        if (onSuccess) onSuccess(response);
        // console.log("On Success");
      })
      .catch((error) => {
        if (onReject) onReject(console.log(error));
      });
  };

// GET DATA DEVICE ID
const getDevice =
  (dispatch) =>
  async ({ register, onAwait, onSuccess, onReject }) => {
    let query = "/" + register;
    if (onAwait) onAwait();

    apiWrapper
      .get("api/v1/" + query, { headers: { "x-access-token": access_token } })
      .then((response) => {
        dispatch({ type: "getData", payload: response.user });
        if (onSuccess) onSuccess(response);
        // console.log("On Success");
      })
      .catch((error) => {
        if (onReject) onReject(console.log(error));
      });
  };

// GET DATA CARD
const registerCard =
  (dispatch) =>
  async ({ user, onAwait, onSuccess, onReject }) => {
    let query = user;
    if (onAwait) onAwait();

    apiWrapper
      .get("api/v1/" + query, { headers: { "x-access-token": access_token } })
      .then((response) => {
        dispatch({ type: "getData", payload: response.user });
        if (onSuccess) onSuccess(response);
        // console.log("On Success");
      })
      .catch((error) => {
        if (onReject) onReject(console.log(error));
      });
  };

// REGISTER USER
const registerUser =
  (dispatch) =>
  async ({ register, onAwait, onSuccess, onReject }) => {
    let query = "/" + register;
    if (onAwait) onAwait();

    apiWrapper
      .post("api/v1/" + query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.user });
        if (onSuccess) onSuccess(response);
        // console.log("On Success");
      })
      .catch((error) => {
        if (onReject) onReject(console.log(error));
      });
  };

// UPDATE CARD SHIFT
const updateCardShift =
  (dispatch) =>
  async ({ register, onAwait, onSuccess, onReject }) => {
    let query = "/" + register;
    if (onAwait) onAwait();

    apiWrapper
      .put("api/v1/" + query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.user });
        if (onSuccess) onSuccess(response);
        // console.log("On Success");
      })
      .catch((error) => {
        if (onReject) onReject(console.log(error));
      });
  };

// GET DATA MASTER by DATE
const getDataMasterDate =
  (dispatch) =>
  async ({ dataMaster, startDate, endDate, onAwait, onSuccess, onReject }) => {
    let query = dataMaster + "/" + startDate + "/" + endDate;
    if (onAwait) onAwait();

    apiWrapper
      .get("api/v1/" + query, {
        headers: { "x-access-token": access_token },
      })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
        // console.log("On Success ====> ");
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Export Context and Provider
export const { Provider, Context } = CreateContext(
  dataWrapperReducer,
  {
    getDataMaster,
    getDataTable,
    getDataHistory,
    getDevice,
    registerCard,
    registerUser,
    updateCardShift,
    getDataMasterDate,
  },
  { data: [] }
);
