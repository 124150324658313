// Import Ant Design Notification's Component
import { CloseCircleOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import { notification } from "antd";

// ERROR GET DEVICE LIST
const showErrorDeviceID = (error) => {
  // console.log("MESSAGE ERROR --> ", error);

  notification.error({
    message: "ERROR !!!",
    description: (
      <>
        {`${error.response.statusText}! ${error.response.data}!`}
        <br />
        Failed to get the Device ID List!
        <br />
        Please, Try Again Later!
      </>
    ),
    icon: <CloseCircleTwoTone twoToneColor={"red"} />,
    placement: "top",
    duration: 15,
    style: { backgroundColor: "#fef0ef", fontWeight: "bold" },
  });
};

// SUCCESS LOGIN NOTIFICATION
const showSuccessLogin = (e) => {
  // console.log("SUCCESS NOTIF REG: ", e);

  notification.success({
    className: "success-notification",
    placement: "top",
    message: "LOGIN SUCCESS!",
    description: "Now Login!",
  });
};

// ERROR LOGIN NOTIFICATION
const showFailedLogin = (e) => {
  // console.log("ERROR NOTIF LOGIN: ", e);

  notification.error({
    className: "error-notification",
    placement: "top",
    message: "LOGIN FAILED!",
    description: (
      <>
        Login Failed!
        <br />
        Please, Input Your Username and Password Correctly!
      </>
    ),
    //  \n ${e.errorFields[0].errors[0]} \n Please, Try Again Later!,
  });
};

// ERROR FETCHING HISTORY DATA
const showError = (error) => {
  // console.log("MESSAGE ERROR", error);

  notification.error({
    message: "ERROR!!!",
    description: (
      <>
        Failed to get the Data!
        <br />
        Please, Try Again Later!
      </>
    ),
    placement: "bottomRight",
    style: { backgroundColor: "#fcdbd9", fontWeight: "bold" },
  });
};

// SUCCESS CARD REGISTRATION
const showSuccessRegCard = (e) => {
  notification.success({
    message: "REGISTER SUCCESS",
    description: "This User's Information has been Registered Successfully!",
    duration: 15,
    placement: "bottomRight",
    style: { backgroundColor: "#d6e4ff", fontWeight: "bold" },
  });
};

// ERROR CARD REGISTRATION
const showErrorRegCard = (e) => {
  notification.error({
    message: "REGISTER ERROR",
    description: (
      <>
        Failed to Register!
        <br />
        Please, Input the Data Correctly and Try Again Later!
      </>
    ),
    duration: 15,
    placement: "bottomRight",
    style: { backgroundColor: "#fcdbd9", fontWeight: "bold" },
  });
};

// SUCCESS USER REGISTRATION
const showSuccessRegUser = (e) => {
  notification.success({
    message: "REGISTER SUCCESS!",
    description: "New User has been Craeted Successfully!",
    placement: "top",
    duration: 15,
    style: {
      backgroundColor: "#d6e4ff",
      fontWeight: "bold",
      width: "100%",
      // fontSize: 40,
    },
  });
};

// ERROR USER REGISTRATION
const showErrorRegUser = (error) => {
  // console.log(error);

  notification.error({
    message: "REGISTER FAILED",
    description: (
      <>
        {error.response.data}!
        <br />
        Failed to Register!
        <br />
        Please, Input the Data Correctly and Try Again Later or Contact The
        Admin!
      </>
    ),
    placement: "top",
    duration: 15,
    style: {
      backgroundColor: "#fcdbd9",
      fontWeight: "bold",
      width: "100%",
      // fontSize: 40,
    },
  });
};

export {
  showSuccessLogin,
  showSuccessRegCard,
  showFailedLogin,
  showErrorRegCard,
  showSuccessRegUser,
  showErrorRegUser,
  showError,
  showErrorDeviceID,
};
