// Import React's Components
import React, { createContext, useReducer, useState } from "react";
import axios from "axios";
import { notification, Form } from "antd";

// Create Context
export default (reducer, actions, defaultValue) => {
  const Context = createContext();

  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const [editingKey, setEditingKey] = useState("");
    const [dataEdit, setDataEdit] = useState(false);

    // const [form] = form.useForm();

    const baseURL = process.env.REACT_APP_BASEURL;

    const boundActions = {};

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    // Handle Save Edit
    // const handleSave = async (key, contentSave) => {
    //   const row = await form.validateFields();
    // console.log("Datane: ", contentSave);
    // console.log("ID: ", row);

    //   axios
    //     .put(`${baseURL}/api/v1/user/`, row)
    //     .then((response) => {
    //       console.log(response);
    //       setEditingKey("");
    //       // console.log(response);
    //       if (response.status == 200) {
    //         showNotif();
    //       } else {
    //         showError();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       setDataEdit(false);
    //     });
    // };

    // let handleFunction = {
    //   handleSave,
    // };

    // let status = {
    //   editingKey,
    //   setEditingKey,
    //   dataEdit,
    //   setDataEdit,
    // };

    return (
      <Context.Provider
        value={{
          state,
          ...boundActions,
          // handleFunction,
          // status
        }}
      >
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};
