// Import React's Components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Import Ant Design's Components
import {
  LeftSquareTwoTone,
  LoginOutlined,
  LogoutOutlined,
  RightSquareTwoTone,
} from "@ant-design/icons";
import { Avatar, Badge, Col, Grid, Row, Typography } from "antd";

const { Title } = Typography;

// Breakpoint
const { useBreakpoint } = Grid;

export default function HeaderComponents() {
  // STATE SUPERADMIN
  const [superadmin, setSuperadmin] = useState(false);

  // SCREEN WIDTH
  const { xxs, xs, sm, md, lg, xl } = useBreakpoint();
  let width = window.screen.width;
  // GET TOKEN
  const auth_token = sessionStorage.getItem("auth-token");
  // GET ROLES
  const roles = sessionStorage.getItem("admin-roles");
  const company_name = sessionStorage.getItem("company_name");
  // console.log("ROLES ===?>" + roles);
  var admin = roles === "true";
  // console.log(val);

  // USE EFFECT
  useEffect(() => {
    if (company_name == "superadmin") {
      setSuperadmin(true);
    } else {
      setSuperadmin(false);
    }
  }, [superadmin]);

  return (
    <>
      {/* Avatar */}
      <Row justify={"space-between"}>
        <Col xs={0} sm={0} md={10} lg={10} xl={16} xxl={16}>
          <Link to={"/"}>
            <Title
              ellipsis
              level={1}
              style={{
                color: "white",
                verticalAlign: "center",
                alignItems: "center",
                fontWeight: "bolder",
                paddingTop: "10px",
                cursor: "pointer",
              }}
            >
              WRAPPING APP
            </Title>
          </Link>
        </Col>

        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
          {superadmin == true ? (
            <Badge>
              <Avatar
                shape="square"
                size={32}
                style={{
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "20px",
                  marginRight: "15px",
                  backgroundColor: "#1890ff",
                  verticalAlign: "center",
                  // marginBottom: "5px",
                }}
              >
                S
              </Avatar>
              <Typography.Text style={{ color: "white" }}>
                Super Admin
              </Typography.Text>
            </Badge>
          ) : admin == true ? (
            <Badge>
              <Avatar
                size={32}
                shape="square"
                style={{
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "20px",
                  marginRight: "10px",
                  backgroundColor: "#f5222d",
                  verticalAlign: "center",
                  // marginBottom: "5px",
                }}
              >
                A
              </Avatar>

              <Typography.Text style={{ color: "white" }}>
                {width >= 500 ? "Administrator" : ""}
              </Typography.Text>
            </Badge>
          ) : (
            <Badge>
              <Avatar
                shape="square"
                size={32}
                style={{
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "20px",
                  marginRight: "15px",
                  backgroundColor: "#52c41a",
                  verticalAlign: "center",
                  // marginBottom: "5px",
                }}
              >
                U
              </Avatar>

              <Typography.Text style={{ color: "white" }}>User</Typography.Text>
            </Badge>
          )}
        </Col>

        {/* Log in / Log out */}
        <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={3}>
          {auth_token ? (
            <Link
              to={"/"}
              onClick={() => {
                sessionStorage.clear();
                // sessionStorage.removeItem("auth-token");
                // sessionStorage.removeItem("company_name");
                // sessionStorage.removeItem("admin-roles");
                // window.location.reload();
                const time = setTimeout(() => {
                  window.location.reload(false);
                }, 100);

                return () => clearTimeout(time);
              }}
            >
              <Typography
                level={1}
                style={
                  xl || lg || md || sm
                    ? {
                        color: "white",
                        verticalAlign: "center",
                        alignItems: "center",
                        fontWeight: "bolder",
                        fontSize: "25px",
                      }
                    : {
                        color: "white",
                        verticalAlign: "center",
                        alignItems: "center",
                        fontSize: "15px",
                      }
                }
              >
                Log out
                <RightSquareTwoTone
                  twoToneColor={admin == true ? "red" : "green"}
                  style={{ margin: 5 }}
                />
              </Typography>
            </Link>
          ) : (
            <Link to={"/login"}>
              <Typography
                style={{
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "20px",
                }}
              >
                Log In
                <LeftSquareTwoTone
                  twoToneColor={"green"}
                  style={{ margin: 5 }}
                />
              </Typography>
            </Link>
          )}
        </Col>
      </Row>
    </>
  );
}
