// Import React's Components
import React, { useState, useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

// Import Page Components
import Drawer from "../Drawer/Drawer";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import FooterPage from "../Layout/Footer";
import LoginPage from "../../Pages/Login/Login";
import History from "../../Pages/History/History";
import RegisterCard from "../../Pages/Card/CardReg";
import RegisterUser from "../../Pages/Register/Register";
import SuperadminDashboard from "../../Pages/Superadmin/Superadmin";

// Code
export default function NavigationPane() {
  // STATE SUPERADMIN
  const [superadmin, setSuperadmin] = useState(false);

  // Get Token User
  const token = sessionStorage.getItem("auth-token");
  const company_name = sessionStorage.getItem("company_name");

  // let superadmin = false;

  // USE EFFECT
  useEffect(() => {
    if (company_name == "superadmin") {
      setSuperadmin(true);
    } else {
      setSuperadmin(false);
    }
  }, [superadmin]);

  // console.log("Company Name ==> ", superadmin);

  return (
    <>
      {token ? (
        superadmin == false ? (
          <BrowserRouter>
            <Drawer />

            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/history" element={<History />} />
              <Route path="/registration-card" element={<RegisterCard />} />
              <Route path="/register-user" element={<RegisterUser />} />
            </Routes>

            <Outlet />

            <FooterPage />
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Drawer />

            <Routes>
              <Route path="/" element={<SuperadminDashboard />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/dashboard-superadmin"
                element={<SuperadminDashboard />}
              />
            </Routes>

            <Outlet />

            <FooterPage />
          </BrowserRouter>
        )
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
          </Routes>

          <Outlet />
        </BrowserRouter>
      )}
    </>
  );
}
