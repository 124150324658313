// Import React's Components
import React, { useState } from "react";

// Import Date Picker
import { DatePicker } from "antd";
import moment from "moment";


// Code
export default function DatePick({ getDate, onSet }) {
  const dateNow = new Date(Date.now());

  const [date, setDate] = useState(dateNow);

  // console.log("INI DATE DI STATE ==> ", date);

  return (
    <>
      <DatePicker
        size="large"
        defaultValue={moment(date, "YYYY-MM-DD")}
        format="YYYY-MM-DD"
        onChange={(newDate) => {
          setDate(moment(newDate).format("YYYY-MM-DD"));
          // console.log(moment(newDate).format("YYYY-MM-DD"));

          getDate(newDate.format("YYYY-MM-DD"));
          if (onSet) onSet(newDate.format("YYYY-MM-DD"));
        }}
        placement="bottomRight"
        style={{ width: "100%" }}
      />
    </>
  );
}
