// Import React's Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// Context & Provider
import { Context as ParkingContext } from "../../api/Context/Context";

// Import Ant Design's Components
import {
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  notification,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

// Import CSS
import "../../App.css";
import LoadingComponents from "../../Components/Loading/Loading";
import {
  showErrorRegCard,
  showSuccessRegCard,
  showError,
} from "../../Components/Notifications/Notifications";

const { Option } = Select;
const responsive = ["xxl", "xl", "lg", "md", "sm", "xs"];

// Editable Cell
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

// Layout Form
// const formItemLayout = {
//   labelCol: {
//     xs: {
//       span: 24,
//     },
//     sm: {
//       span: 8,
//     },
//   },
//   wrapperCol: {
//     xs: {
//       span: 24,
//     },
//     sm: {
//       span: 16,
//     },
//   },
// };

// Code
export default function RegisterCard() {
  // State Management Editable Cells
  const { state, registerCard, handleFunction, status } =
    useContext(ParkingContext);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [dataEdit, setDataEdit] = useState(false);

  // State Management
  const [data, setData] = useState([]);

  // Get Token and URL
  const url = process.env.REACT_APP_AUTHURL;
  const token = sessionStorage.getItem("auth-token");

  // HANDLE FUNCTION FROM CONTEXT
  // const { handleSave } = handleFunction;

  // STATUS CONTEXT
  // const { editingKey, setEditingKey, dataEdit, setDataEdit } = status;

  // State Management Loading
  const [load, setLoad] = useState(true);

  const isEditing = (record) => record.id === editingKey;

  //   console.log(editingKey);

  // Handle Edit
  const handleEdit = (record) => {
    // console.log("Editable data: ", record);
    setDataEdit(true);
    form.setFieldsValue({
      //   id: record.id,
      card_id: "",
      operator_name: "",
      shift: 0,
      ...record,
    });
    setEditingKey(record.id);
    // console.log("Editable data: ", record);
  };

  // Handle Cancel
  const handleCancel = () => {
    setEditingKey("");
  };

  // Create Columns
  const columns = [
    {
      title: "No",
      dataIndex: "",
      key: "",
      width: 100,
      // sorter: (a, b) => a.id - b.id,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Card ID",
      dataIndex: "card_id",
      key: "card_id",
      editable: true,
      responsive: responsive,
      //     render: (_, record) => {
      //       return record.card_id
      //   }
    },
    {
      title: "User Name",
      dataIndex: "operator_name",
      key: "operator_name",
      editable: true,
      // responsive: ["lg"],
    },
    {
      title: "Location",
      dataIndex: "lokasi",
      key: "lokasi",
      editable: true,
      // responsive: ["lg"],
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      editable: responsive,
      // responsive: ["lg"],
    },
    {
      title: "Action",
      dataIndex: "action",
      responsive: responsive,
      render: (_, record) => {
        const editable = isEditing(record);
        // console.log("Kimcil: ", record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => {
                handleSave(record.id);
                // console.log("Ini yang mau di save: ", record);
              }}
              style={{
                marginRight: 8,
              }}
            >
              {/* <Popconfirm title="Sure to Save?" onConfirm={handleCancel}> */}
              Save
              {/* </Popconfirm> */}
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={handleCancel}>
              Cancel
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => {
              handleEdit(record);
              //   console.log("Yang dibawa: ", record);
            }}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  // Get Data
  const getDataRegister = () => {
    registerCard({
      user: "user",
      onAwait: () => "Awaiting Data",
      onSuccess: (response) => {
        // console.log(response);
        setData(response.data);
      },
      onReject: (error) => {
        showError(error);
        console.log(error);
      },
    });

    // axios
    //   .get(`${baseU}/api/v1/user`)
    //   .then((response) => {
    //     setData(response.data);
    //     // console.log("Data User = ", response.data);
    //   })
    //   .catch((err) => {
    //     console.log("Throw Error: ", err);
    //   });
  };

  // Use Effects for Data
  useEffect(() => {
    getDataRegister();

    const interval = setInterval(() => {
      setLoad(false);
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, [dataEdit]);

  // Handle Save Edit
  const handleSave = async (key, contentSave) => {
    const row = await form.validateFields();
    // console.log("Datane: ", contentSave);
    // console.log("ID: ", row);

    const accesstoken = { "x-access-token": token };

    axios
      .put(`${url}api/v1/user`, row, { headers: accesstoken })
      .then((response) => {
        // console.log(response);
        setEditingKey("");
        // console.log(response);
        if (response.status == 200) {
          showSuccessRegCard(response);
        } else {
          showErrorRegCard(response);
        }
      })
      .catch((error) => {
        console.log(error);
        showError(error);
      })
      .finally(() => {
        setDataEdit(false);
      });

    // try {
    //   //   const a = await form.(key);
    //   const row = await form.validateFields();
    //   const newData = [...data];
    //   const index = newData.findIndex((item) => key === item.key);

    //   console.log("Index: ", row);
    //   if (index > -1) {
    //     const item = newData[index];

    //     // console.log(newData[index]);
    //     newData.splice(index, 1, {
    //       ...item,
    //       ...row,
    //     });
    //     // axios
    //     //   .put(`http://localhost:11001/api/v1/user/`, {
    //     //     newData,
    //     //   })
    //     setData(newData[index]);
    //     console.log("NEWDATA:", newData[0]);
    //     setEditingKey("");
    //   } else {
    //     // console.log(row);
    //     newData.push(row);
    //     setData(newData);
    //     setEditingKey("");
    //   }
    // } catch (errInfo) {
    //   console.log("Validate Failed:", errInfo);
    // }
  };

  // Render Editable Cells
  const mergedColumns = columns.map((col) => {
    // console.log("Merged Columns: ", col);
    if (!col.editable) {
      return col;
    } else {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "shift" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  });

  // console.log(apiWrapper);
  // console.log(data);

  // Loading State
  if (load == true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Layout
        style={{ minHeight: "90vh", marginTop: 65, backgroundColor: "#e6f7ff" }}
      >
        <Content style={{ padding: 10, margin: 10 }}>
          <Card style={{ backgroundColor: "#95de64" }}>
            <Typography.Title style={{ textAlign: "center", color: "white" }}>
              REGISTER CARD
            </Typography.Title>
          </Card>
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          {/* <Row align="middle" justify="center" style={{ minHeight: "90vh" }}>
            <Col>
              <Card
                type="flex"
                align="middle"
                style={{
                  width: "100%",
                  placeContent: "center",
                  alignSelf: "center",
                  borderRadius: "5px",
                }}
              > */}
          <Form form={form} component={false}>
            <Table
              title={() => "Card Table List"}
              size="large"
              scroll={{ x: "125" }}
              bordered={true}
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              rowKey={(record) => record.id}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          </Form>
          {/* <Form form={form} {...formItemLayout}>
                  <Form.Item label="Card Id" name="card_id">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item label="Name" name="operator_name">
                    <Input style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item label="Shift" name="shift">
                    <Select placeholder="Select Shift for this Card">
                      <Option value={1}>Shift 1</Option>
                      <Option value={2}>Shift 2</Option>
                      <Option value={3}>Shift 3</Option>
                    </Select>
                  </Form.Item>
                </Form> */}
          {/* </Card>
            </Col>
          </Row> */}
        </Content>
      </Layout>
    </>
  );
}
