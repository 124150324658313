import axios from "axios";

const apiWrapper = axios.create({
  baseURL:
    // process.env.REACT_APP_URLCLIENT,
    // process.env.REACT_APP_BASEURL,
    // process.env.REACT_APP_AUTHURL,
    process.env.REACT_APP_URL_PROD
      ? process.env.REACT_APP_URL_PROD
      : process.env.REACT_APP_AUTHURL,
});

export default apiWrapper;
// export authApi;
