import { Layout } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React from "react";

export default function FooterPage() {
  return (
    <>
      <Layout>
        <Footer
          style={{
            color: "white",
            textAlign: "center",
            backgroundColor: "#001529",
            fontWeight: "625",
            width: "100%",
            // position: "absolute",
          }}
        >
          ANGKASA PURA @2022
        </Footer>
      </Layout>
    </>
  );
}
