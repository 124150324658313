// Import React's Library
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";

// Import Ant Design's Components
import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import {
  DownloadOutlined,
  FileExcelOutlined,
  FileExcelTwoTone,
  SyncOutlined,
} from "@ant-design/icons";

// Import Context
import { Context as WrapperContext } from "../../api/Context/Context";

// Import Ant Design's CSS
import "antd/dist/antd.css";
import "../../App.css";

// Import Components
import SelectShift from "../../Components/Selector/SelectShift";
import DatePick from "../../Components/Date/DatePicker";
import Column from "antd/lib/table/Column";
import LoadingComponents from "../../Components/Loading/Loading";
import DeviceSelector from "../../Components/Device/DeviceSelector";
import { showError } from "../../Components/Notifications/Notifications";

// const { Title } = Typography;

// Create Styles
const styles = {
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "bold",
  color: "#096dd9",
};

// Button Styles
const buttonStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  paddingBottom: "25px",
};

// Create Table's Columns
const columns = [
  {
    title: "No",
    dataIndex: "",
    key: "",
    width: 100,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  // {
  //   title: "ID",
  //   dataIndex: "id",
  //   key: "id",
  //   sorter: (a, b) => a.id - b.id,
  // },
  {
    title: "ID Transactions",
    dataIndex: "trx_id",
    key: "trx_id",
    width: 150,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Nama",
    dataIndex: "operator_name",
    key: "operator_name",
  },
  {
    title: "ID Card",
    dataIndex: "card_id",
    key: "card_id",
  },
  {
    title: "Time Stamp",
    dataIndex: "datetime",
    key: "datetime",
    sorter: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
    // sortDirections: ["ascend"],
  },
  {
    title: "Wrapper Length",
    dataIndex: "plastic_length",
    key: "plastic_length",
    render: (_, record) => {
      return (parseInt(record.plastic_length) * 0.2).toFixed(2) + " m";
    },
  },
  {
    title: "Event",
    dataIndex: "event_type",
    key: "event_type",
    filters: [
      {
        text: "Default",
        value: 0,
      },
      {
        text: "Start-Pause-Stop",
        value: 1,
      },
      {
        text: "Start-Pause-Emergency",
        value: 2,
      },
      {
        text: "Start-Emergency",
        value: 3,
      },
      {
        text: "Auto Up/Down",
        value: 4,
      },
      {
        text: "Fault/Error System",
        value: 5,
      },
    ],
    onFilter: (value, record) => {
      return record.event_type === value;
    },
    render: (text) => {
      let texts = "";
      let colors = "";
      // console.log(text);
      switch (text) {
        case 0:
          {
            texts = "Default";
            colors = "#8c8c8c";
          }
          break;
        case 1:
          {
            texts = "Start-Pause-Pause-Stop";
            colors = "geekblue";
          }
          break;
        case 2:
          {
            texts = "Start-Pause-Pause-Emergency";
            colors = "green";
          }
          break;
        case 3:
          {
            texts = "Start-Emergency-Start-Emergency";
            colors = "yellow";
          }
          break;
        case 4:
          {
            texts = "Start-Auto Stop(UP/DOWN)";
            colors = "red";
          }
          break;
        case 5:
          {
            texts = "Fault/Error System";
            colors = "magenta";
          }
          break;

        default:
          break;
      }
      return <Tag color={colors}>{texts}</Tag>;
    },
  },
];

export default function History() {
  const dates = new Date(Date.now());
  // CONTEXT
  const { state, getDataHistory } = useContext(WrapperContext);
  // State Management
  const [dataTable, setDataTable] = useState(null);
  // const [dataCurrent, setDataCurrent] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  // Device Value
  const [device, setDevice] = useState();
  // Shift Value
  const [value, setValue] = useState(1);

  // Date
  const [date, setDate] = useState(moment(dates).format("YYYY-MM-DD"));

  // State Management Loading
  const [load, setLoad] = useState(true);

  // Set Pick for Date
  const getDate = (newDate) => {
    setDate(moment(newDate).format("YYYY-MM-DD"));
  };

  // Set Pick for Shift
  const getDeviceId = (val) => {
    setDevice(val);
    // console.log(newValue);
  };

  // Set Pick for Shift
  const getShift = (newValue) => {
    setValue(newValue);
    // console.log(newValue);
  };

  const loadData = async () => {
    // setIsBusy(true);
    getDataHistory({
      shift: value,
      dateTime: date,
      onAwait: () => "Awaiting Data",
      onSuccess: (response) => {
        // console.log("SUCCESS: ", response.data);
        // if (
        //   dataTable == null ||
        //   response.data.dataTable.length != dataTable.length
        // ) {
        //   setIsBusy(true);
        //   setTimeout(() => {
        //     setDataTable(response.data.dataTable);
        //     setIsBusy(false);
        //   }, 2000);
        // }
        setDataTable(response.data);
      },
      onReject: (error) => console.log(error),
    });
  };

  // Use Effect
  useEffect(() => {
    loadData();

    const interval = setInterval(() => {
      //   loadData();
      setLoad(false);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Download File as Excel
  const downloadExcel = async (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Report File.xlsx");
  };

  // console.log("Shift: ", value);
  // console.log("Device ID: ", device);
  // console.log("Current Date: ", date);
  // console.log("State: ", state);
  // console.log("Data: ", dataTable);

  if (load == true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          marginTop: 65,
          backgroundColor: "#e6f7ff",
        }}
      >
        <Content style={{ padding: 10, margin: 10 }}>
          <Card
            bordered={true}
            hoverable={true}
            style={{
              // backgroundColor: "#919191",
              height: "90%",
            }}
          >
            <Typography.Title style={{ textAlign: "center", color: "#f56a00" }}>
              History Wrapping
            </Typography.Title>
          </Card>
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          <Row justify="space-between">
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                bordered={true}
                style={{
                  // backgroundColor: "#919191",
                  height: "90%",
                }}
              >
                <Typography.Title
                  style={{
                    textAlign: "left",
                    color: "#f56a00",
                    marginTop: 10,
                    padding: "0px 0px 30px",
                  }}
                >
                  Select Device
                  <DeviceSelector
                    getDeviceId={getDeviceId}
                    onSet={(dev) => {
                      setDevice(dev);
                      // console.log("On Set Value Shift: ", dev);

                      getDataHistory({
                        deviceID: dev,
                        shift: value,
                        dateTime: date,
                        onAwait: () => {
                          ("On Await");
                        },
                        onSuccess: (response) => {
                          setDataTable(response.data);
                          //   console.log("Data On Change Shift : ", response.data);
                        },
                        onReject: (error) => {
                          showError(error);
                          console.log(error);
                        },
                      });
                    }}
                  />
                </Typography.Title>
              </Card>
            </Col>

            <Col xxl={11} xl={11} lg={8} md={8} sm={24} xs={24}>
              <Card
                bordered={true}
                // style={{ backgroundColor: "#919191" }}
              >
                <Typography.Title
                  style={{
                    textAlign: "left",
                    color: "#f56a00",
                    marginTop: 10,
                  }}
                >
                  Select Shift
                  <SelectShift
                    getShift={getShift}
                    onSet={(val) => {
                      setValue(val);
                      //   console.log("On Set Value Shift: ", val);

                      getDataHistory({
                        deviceID: device,
                        shift: val,
                        dateTime: date,
                        onAwait: () => {
                          ("On Await");
                        },
                        onSuccess: (response) => {
                          setDataTable(response.data);
                          //   console.log("Data On Change Shift : ", response.data);
                        },
                        onReject: (error) => {
                          showError(error);
                          console.log(error);
                        },
                      });
                    }}
                  ></SelectShift>
                </Typography.Title>
              </Card>
            </Col>

            <Col xxl={11} xl={11} lg={8} md={8} sm={24} xs={24}>
              <Card
                bordered={true}
                // style={{ backgroundColor: "#919191" }}
              >
                <Typography.Title
                  style={{
                    textAlign: "left",
                    color: "#f56a00",
                    marginTop: 10,
                  }}
                >
                  Pick a Date
                  <DatePick
                    getDate={getDate}
                    onSet={(newDate) => {
                      setDate(moment(newDate).format("YYYY-MM-DD"));
                      //   console.log("On Set Date: ", date);

                      getDataHistory({
                        deviceID: device,
                        shift: value,
                        dateTime: moment(newDate).format("YYYY-MM-DD"),
                        onAwait: () => {
                          ("On Await");
                        },
                        onSuccess: (response) => {
                          setDataTable(response.data);
                          //   console.log("Data On Change Date : ", response.data);
                        },
                        onReject: (error) => {
                          showError(error);
                          console.log(error);
                        },
                      });
                    }}
                  ></DatePick>
                </Typography.Title>
              </Card>
            </Col>
          </Row>
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          {/* {!state.data.length ? ( */}
          <Table
            title={() => "TABLE TRANSACTION HISTORY"}
            columns={columns}
            dataSource={dataTable}
            bordered={true}
            style={styles}
            loading={isBusy}
            pagination={{ position: ["topRight"] }}
            scroll={{ x: "125" }}
            rowKey={(record) => record.id}
            footer={() => "History Data"}
          />
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          <Row span={24}>
            <Col xl={12} md={24} xs={24} sm={24}>
              <Space direction="horizontal" style={buttonStyle}>
                <CSVLink
                  data={dataTable ? dataTable : []}
                  onClick={(e) => console.log(e)}
                  filename="Report.csv"
                  className="csv-button"
                >
                  <DownloadOutlined style={{ paddingRight: "10px" }} />
                  <Typography.Text style={{ color: "white" }}>
                    Export to CSV Files
                  </Typography.Text>
                </CSVLink>
              </Space>
            </Col>

            <Col xl={12} md={24} xs={24} sm={24}>
              <Space direction="horizontal" style={buttonStyle}>
                <Button
                  type="primary"
                  danger={true}
                  shape="round"
                  icon={<FileExcelOutlined />}
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "center",
                    // width: "100%",
                    height: "90%",
                    fontSize: 40,
                  }}
                  onClick={() => downloadExcel(dataTable ? dataTable : [])}
                >
                  <Typography.Text ellipsis style={{ color: "white" }}>
                    Export to Excel Files
                  </Typography.Text>
                </Button>
              </Space>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
