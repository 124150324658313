// Import React's Components
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";

// CONTEXT and PROVIDER
import { Context as WrapperContext } from "../../api/Context/Context";

// Import Ant Design Components
import { Col, Divider, Row, Select, Space, Spin, Typography } from "antd";
import debounce from "lodash/debounce";
import { showError, showErrorDeviceID } from "../Notifications/Notifications";
import { BulbTwoTone } from "@ant-design/icons";
import { io } from "socket.io-client";

// function DebounceSelect({ fetchOptions, debounceTimeout = 1000, ...props }) {
//   const [fetching, setFetching] = useState(false);
//   const [options, setOptions] = useState([]);

//   const fetchRef = useRef(0);
//   const debounceFetcher = useMemo(() => {
//     const loadOptions = (value) => {
//       fetchRef.current += 1;
//       const fetchId = fetchRef.current;
//       setOptions([]);
//       setFetching(true);
//       fetchOptions(value).then((newOptions) => {
//         if (fetchId !== fetchRef.current) {
//           // for fetch callback order
//           return;
//         }
//         setOptions(newOptions);
//         setFetching(false);
//       });
//     };
//     return debounce(loadOptions, debounceTimeout);
//   }, [fetchOptions, debounceTimeout]);

//   return (
//     <Select
//       labelInValue
//       filterOption={false}
//       onSearch={debounceFetcher}
//       notFoundContent={fetching ? <Spin size="small" /> : null}
//       {...props}
//       options={options}
//     />
//   );
// }

// Usage of DebounceSelect
// async function fetchUserList(deviceID) {
//   const token = sessionStorage.getItem("auth-token");

//   console.log("fetching deviceID ==>>", deviceID);

//   return axios
//     .get("http://103.41.206.170:22002/api/v1/list-device", {
//       headers: { "x-access-token": token },
//     })
//     .then((response) => {
//       console.log(response);
//       // response.data.json();
//       response.data.map(
//         (device) =>
//           // {
//           // console.log(device);
//           ({
//             label: device.device_id,
//             value: device.device_id,
//           })
//         // }
//       );
//     });
// .then((response) => {
//   console.log(response.data);

//   response.results.map((user) => ({
//     label: `${user.device_id}`,
//     value: user.device_id,
//   }));
// });

// return fetch("https://randomuser.me/api/?results=5")
//   .then((response) => {
//     console.log(response);
//     response.json();
//   })
//   .then((body) => {
//     console.log(body);
//     body.results.map((user) => ({
//       label: `${user.name.first} ${user.name.last}`,
//       value: user.login.username,
//     }));
//   });
// }

// CODE
export default function DeviceSelector({ getDeviceId, onSet, is_hidden }) {
  // CONTEXT
  const { state, getDevice } = useContext(WrapperContext);
  // STATE MANAGEMENTS
  const [device, setDevice] = useState();
  const [value, setValue] = useState([]);
  const [status, setStatus] = useState([]);
  const [hide, setHide] = useState(false);
  const [indicators, setIndicators] = useState([]);

  const url = process.env.REACT_APP_AUTHURL;
  const ws = process.env.REACT_APP_BASEIO_DEVICEID;

  // console.log(ws);

  // WEB SOCKET
  const socket = io(ws);

  // GET TOKEN
  const token = sessionStorage.getItem("auth-token");

  // GET DEVICE ID
  const getDeviceID = async () => {
    await axios
      .get(`${url}api/v1/list-device`, {
        headers: { "x-access-token": token },
      })
      .then((response) => {
        console.log(response);
        // setValue(response.data);

        let option = response?.data.map((dev) => ({
          value: dev?.device_id,
          label: dev?.device_id,
        }));

        let statusdevice = response?.data.map((statdev) => ({
          value: statdev?.device_id,
          label: statdev?.device_id,
          // status: statdev.status_device,
        }));

        // console.log("OPTION VALUE ===>> ", option);
        setValue(option);
        setStatus(statusdevice);
      })
      .catch((error) => {
        console.log(error);
        showErrorDeviceID(error);
      })
      .finally();
  };

  // USE EFFECT
  useEffect(() => {
    getDeviceID();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (status?.length > 0 && !cleanUp) {
      generateIndicator(status);
    }

    return () => {
      cleanUp = true;
    };
  }, [status]);

  // IS HIDDEN
  useEffect(() => {
    if (is_hidden == true) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [is_hidden]);

  useEffect(() => {
    // Connected
    socket.on("connnect", (response) => {
      console.log("connected to server", response);
      // setVisible(false);
    });

    // Error Not Connected
    socket.io.on("error", (error) => {
      console.log("Error connecting to server", error);
      // showErrorDeviceID(error);
      // setVisible(true);
    });

    socket.on("ping", (data) => {
      getDeviceID();
      console.log("PING DATA: ", data);
      // setVisible(false);
    });
  }, []);

  // Create Indicator
  const generateIndicator = async (val) => {
    const data = val;

    console.log(data);

    var indicator = [];

    for (let i = 0; i < status?.length; i++) {
      // console.log(i);

      indicator.push(
        <>
          <Col className="indicator" key="indicator-column">
            <Row justify={"center"} key="indicator-row">
              <BulbTwoTone
                twoToneColor={"#3dbd7d"}
                key={status[i].device_id}
                style={{
                  fontSize: 50,
                }}
              />
            </Row>

            <Row className="indicator-status" key={status[i].label + " row"}>
              <Typography
                style={{ fontSize: 20 }}
                className="label"
                key={status[i].label}
              >
                {status[i].label}
              </Typography>
            </Row>
          </Col>
        </>
      );
    }

    // console.log("Indicator --> ", indicator);
    setIndicators(indicator);
    // return (
    //   <>
    //     <Row>
    //       <BulbTwoTone
    //         twoToneColor={status[i].status == "Online" ? "green" : "red"}
    //       />
    //     </Row>
    //   </>
    // );
  };

  // console.log("VALUE ==>> ", value);
  // console.log("STATUS ==>> ", status);
  console.log("DEVICE ==>> ", device);

  return (
    <>
      {/* <DebounceSelect
        mode="multiple"
        value={value}
        placeholder="Select users"
        fetchOptions={fetchUserList}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        style={{
          width: "100%",
        }}
      /> */}
      <Divider />

      <Row justify={"space-evenly"} key="device-selector">
        {indicators}
      </Row>

      <Divider />

      <Typography.Title>Device Selector</Typography.Title>
      <Select
        // defaultValue={{

        // }}
        allowClear
        showSearch
        className="device-selector"
        key={`device-selector`}
        style={{ width: "100%" }}
        value={device}
        onChange={(deviceid) => {
          setDevice(deviceid);
          // console.log(deviceid);

          getDeviceId(deviceid);
          if (onSet) onSet(deviceid);
        }}
        options={value}
      />
    </>
  );
}
