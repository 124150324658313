// Import React's Library
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { io } from "socket.io-client";

// Import Ant Design's Components
import {
  Alert,
  Card,
  Col,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { AlertOutlined, SyncOutlined } from "@ant-design/icons";

// Import Context
import { Context as WrapperContext } from "../../api/Context/Context";

// Import Ant Design's CSS
import "antd/dist/antd.css";

// Import Components
import CardWrapping from "../../Components/Card/Card";
import LoadingComponents from "../../Components/Loading/Loading";
import DeviceSelector from "../../Components/Device/DeviceSelector";
import { showError } from "../../Components/Notifications/Notifications";

// const { Title } = Typography;
var d = "";

// Create Styles
const styles = {
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "bold",
  color: "#096dd9",
};

// Create Table's Columns
const columns = [
  {
    title: "No",
    dataIndex: "",
    key: "",
    width: 100,
    // sorter: (a, b) => a.id - b.id,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  // {
  //   title: "ID",
  //   dataIndex: "id",
  //   key: "id",
  //   width: 100,
  //   sorter: (a, b) => a.id - b.id,
  // },
  {
    title: "ID Transactions",
    dataIndex: "trx_id",
    key: "trx_id",
    width: 150,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Nama",
    dataIndex: "operator_name",
    key: "operator_name",
  },
  {
    title: "ID Card",
    dataIndex: "card_id",
    key: "card_id",
    width: 100,
  },
  {
    title: "Time Stamp",
    dataIndex: "datetime",
    key: "datetime",
    width: 250,
    sorter: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
    // sortDirections: ["ascend"],
  },
  {
    title: "Wrapper Length",
    dataIndex: "plastic_length",
    key: "plastic_length",
    width: 150,
    render: (_, record) => {
      return (parseInt(record.plastic_length) * 0.2).toFixed(2) + " m";
    },
  },
  {
    title: "Event",
    dataIndex: "event_type",
    key: "event_type",
    width: 500,
    filters: [
      {
        text: "Default",
        value: 0,
      },
      {
        text: "Start-Pause-Stop",
        value: 1,
      },
      {
        text: "Start-Pause-Emergency",
        value: 2,
      },
      {
        text: "Start-Emergency",
        value: 3,
      },
      {
        text: "Auto Up/Down",
        value: 4,
      },
      {
        text: "Fault/Error System",
        value: 5,
      },
    ],
    onFilter: (value, record) => {
      return record.event_type === value;
    },
    render: (text) => {
      let texts = "";
      let colors = "";
      // console.log(text);
      switch (text) {
        case 0:
          {
            texts = "Default";
            colors = "#8c8c8c";
          }
          break;
        case 1:
          {
            texts = "Start-Pause-Pause-Stop";
            colors = "geekblue";
          }
          break;
        case 2:
          {
            texts = "Start-Pause-Pause-Emergency";
            colors = "green";
          }
          break;
        case 3:
          {
            texts = "Start-Emergency-Start-Emergency";
            colors = "yellow";
          }
          break;
        case 4:
          {
            texts = "Start-Auto Stop(UP/DOWN)";
            colors = "red";
          }
          break;
        case 5:
          {
            texts = "Fault/Error System";
            colors = "magenta";
          }
          break;
        case 6:
          {
            texts = "Start-Up";
            colors = "#52c41a";
          }
          break;

        default:
          {
            texts = `UNKNOWN-${text}`;
            colors = "brown";
          }
          break;
      }
      return <Tag color={colors}>{texts}</Tag>;
    },
  },
];

export default function Dashboard() {
  // CONTEXT
  const { state, getDataTable } = useContext(WrapperContext);
  // State Management
  const [dataTable, setDataTable] = useState(null);
  const [dataCurrent, setDataCurrent] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  // Device ID
  const [device, setDevice] = useState(null);
  const [hidden, setHidden] = useState(false);
  // Error State
  const [visible, setVisible] = useState(false);
  // State Management Loading
  const [load, setLoad] = useState(true);

  // GET USER'S TOKEN
  const auth_token = sessionStorage.getItem("auth-token");
  const company_name = sessionStorage.getItem("company_name");

  const access_token = { "x-access-token": auth_token };
  // console.log("ISI STORE ==>> " + store.token);
  // console.log("TIPE ==>> " + typeof store);
  // console.log("ISI TOKEN ==>> " + auth_token);
  // console.log("NAMA PT ==>> " + company_name);

  // URL SOCKET IO
  const url = process.env.REACT_APP_AUTHURL;
  const urlIO = process.env.REACT_APP_BASEIO;
  // const socket = io(urlIO);
  var socket = io(`ws://103.41.206.170:3002`, { transports: ["websocket"] });

  // Get Device ID Value
  const getDeviceId = (value) => {
    setDevice(value);
  };

  // Load Data
  const loadData = async (idDevice) => {
    // setIsBusy(true);
    // getDataTable({
    //   dataTable: "data",
    //   deviceID: device,
    //   onAwait: (deviceID) => {
    //     "Awaiting Data";
    //     console.log("Current DEV ID : ", deviceID);
    //   },
    //   onSuccess: (response) => {
    //     console.log("LOADED DATA : ", device);
    //     console.log("SUCCESS: ", response);
    //     setDataTable(response.data.data);
    //     setDataCurrent(response.data.current);
    // if (
    //   dataTable == null ||
    //   response.data.data.length != dataTable.length
    // ) {
    //   setIsBusy(true);
    //   setTimeout(() => {
    //     setDataTable(response.data.data);
    //     // setShift(response.data.current.shift)
    //     // setIsBusy(false);
    //   }, 2000);
    // }
    //   },
    //   onReject: (error) => {
    //     setVisible(true);
    //     console.log(error);
    //   },
    // });
    // console.log(idDevice);

    if (idDevice) {
      await axios
        .get(`${url}api/v1/data/${idDevice}`, { headers: access_token })
        .then((response) => {
          console.log("SUCCESS ==>>>>> ", response);
          setDataTable(response.data.data);
          setDataCurrent(response.data.current);
        })
        .catch((error) => {
          console.log(error);
          // setVisible(true);
        })
        .finally((final) => {
          console.log(final);
        });
    }
  };

  // Use Effect
  useEffect(() => {
    loadData(d);

    const interval = setInterval(() => {
      // loadData();
      setLoad(false);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // Connected
    socket.on("connnect", (response) => {
      console.log("connected to server", response);
      setVisible(false);
    });
    socket.on("connnection", (sockets) => {
      console.log(sockets.id);
    });

    // // Error Not Connected
    // socket.on("error", (error) => {
    //   console.log("Error connecting to server", error);
    //   setVisible(true);
    // });

    socket.on("pong", (data) => {
      console.log("Data available");
      // console.log(d);
      loadData(d);
      console.log("PONG DATA: ", data);
      setVisible(false);
    });
    return () => {
      socket.off("connect");
      socket.off("connnection");
      socket.off("error");
      socket.off("pong");
    };
  }, []);

  // const handleClose = () => {
  //   setVisible(!visible);
  // };

  // console.log("Data Table State:", state);
  // console.log("Data Table:", dataTable == null ? 0 : dataTable.length);
  // console.log("Data Current: ", dataCurrent);
  // console.log("DEVICE ID: ", device);
  // console.log("INI D++>", d);

  if (load == true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          marginTop: 65,
          backgroundColor: "#e6f7ff",
        }}
      >
        <Content style={{ padding: 10, margin: 10 }}>
          <Card
            className="card-dashboard"
            style={{
              margin: "0px 0px 30px",
            }}
          >
            <Typography.Title style={{ textAlign: "center" }}>
              {`DASHBOARD WRAPPING ${company_name}`}
            </Typography.Title>
          </Card>

          {visible && (
            <Alert
              message="ERROR!"
              description="The Server are offline, no data is received! Please, contact the Admin of the Server to reactivate the Server!"
              type="error"
              showIcon
              style={{
                margin: "0px 0px 30px",
              }}
            />
          )}

          <Row
            justify="space-between"
            style={{
              width: "100%",
              margin: "0px 0px 30px",
            }}
          >
            <Card
              bordered={true}
              className="card-dashboard"
              style={{
                width: "100%",
              }}
            >
              <Row
                className="row-header"
                justify="space-evenly"
                style={{ width: "100% " }}
              >
                <Col flex={"auto"} span={24} className="col-title">
                  <Typography.Title
                    style={{
                      textAlign: "left",
                      marginTop: 10,
                    }}
                  >
                    Device Indicator
                    {/* <Select
                    options={options}
                    style={{ width: "100%" }}
                    value={device}
                    onChange={getDeviceId}
                  /> */}
                  </Typography.Title>
                </Col>

                {/*
                <Col
                  span={12}
                  className="col-switch"
                  style={{
                    // width: "100%",
                    // textAlign: "center",
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AlertOutlined
                    className="alert-icon"
                    style={{
                      color: hidden == true ? "#f5222d" : "#52c41a",
                      fontSize: 30,
                      fontWeight: 750,
                    }}
                  />

                  <Typography
                    className="text-indicator"
                    style={{
                      fontSize: 22,
                      fontWeight: 750,
                      margin: 0,
                      padding: "0px 30px 0px",
                      color: "#1890ff",
                    }}
                  >
                    {hidden == true ? `Show Indicator` : `Hide Indicator`}
                  </Typography>

                  <Switch
                    className="switch-btn"
                    key="switch-btn"
                    size="default"
                    checkedChildren="Hide"
                    unCheckedChildren="Show"
                    onClick={setHidden}
                    style={{
                      margin: 10,
                      // padding: "10px 0px 0px",
                    }}
                  />
                </Col>
                  */}
              </Row>

              <DeviceSelector
                getDeviceId={getDeviceId}
                // is_hidden={hidden}
                onSet={(val) => {
                  setDevice(val);
                  // console.log("On Set Value Shift: ", val);
                  d = val;
                  getDataTable({
                    dataTable: "data",
                    deviceID: val,
                    onAwait: () => {
                      ("On Await");
                    },
                    onSuccess: (response) => {
                      console.log("Data On Change Shift : ", response.data);
                      setDataTable(response.data.data);
                      setDataCurrent(response.data.current);
                    },
                    onReject: (error) => {
                      showError(error);
                      console.log(error);
                    },
                  });
                }}
              />
            </Card>
            {/* </Col> */}
          </Row>

          <CardWrapping dataCard={dataCurrent} />
        </Content>

        {typeof device !== "undefined" ? (
          <Content style={{ padding: 10, margin: 10 }}>
            {/* {!state.data.length ? ( */}
            <Table
              title={() => "TABLE TRANSACTION"}
              columns={columns}
              dataSource={dataTable}
              bordered={true}
              style={styles}
              pagination={{
                position: ["topRight"],
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
              }}
              // loading={isBusy}
              rowKey={(record) => record.id}
              scroll={{ x: "150" }}
              footer={() => "Current Transaction"}
            />
          </Content>
        ) : (
          <></>
        )}
      </Layout>
    </>
  );
}
