// Import React's Components
import React, { useContext, useEffect, useState } from "react";

// Context abd Provider
import { Context as WrapperContext } from "../../api/Context/Context";

// Import Ant Design Components
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import {
  LockTwoTone,
  ProfileTwoTone,
  SmileTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import LoadingComponents from "../../Components/Loading/Loading";
import {
  showErrorRegUser,
  showSuccessRegUser,
} from "../../Components/Notifications/Notifications";
import axios from "axios";

// FORM ITEM LAYOUT
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
    xl: {
      span: 12,
    },
    xxl: {
      span: 12,
    },
  },
};

// CODE
export default function RegisterUser() {
  // CONTEXT
  const { state, registerUser } = useContext(WrapperContext);
  // STATE MANAGEMENT
  // Data Register
  const [username, setUsername] = useState();
  const [name, setName] = useState();
  const [password, setPassword] = useState();
  // Loading State
  const [load, setLoad] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // Get Token and URL
  const url = process.env.REACT_APP_AUTHURL;
  const token = sessionStorage.getItem("auth-token");

  // Use Effect
  useEffect(() => {
    const interval = setTimeout(() => {
      setLoad(false);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // HANDLE REGISTER
  const submitRegister = (datasubmit) => {
    // console.log("DATA SUBMIT REGISTER ===>> ", datasubmit);

    // registerUser({
    //   register: "register-user",
    //   onAwait: () => "Awaiting Data",
    //   onSuccess: (response) => {
    //     console.log(response);
    //     showSuccessRegUser(response);
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //     showErrorRegUser(error);
    //   },
    // });

    const datauser = {
      name: datasubmit.name,
      username: datasubmit.username,
      password: datasubmit.password,
      admin: 0,
    };

    const accesstoken = { "x-access-token": token };

    axios
      .post(`${url}register-user`, datauser, { headers: accesstoken })
      .then((response) => {
        // console.log(response);
        if (response.status == 200 || 201) {
          showSuccessRegUser(response);
        } else {
          showErrorRegUser(response);
        }
      })
      .catch((error) => {
        console.log(error);
        showErrorRegUser(error);
      })
      .finally(form.resetFields());
  };

  // ON FINISH FAILED
  const onFinsihFailed = (error) => {
    console.log(error);
    showErrorRegUser(error);
  };

  if (load == true) {
    return <LoadingComponents />;
  }

  // console.log(url);

  return (
    <>
      <Layout
        style={{ minHeight: "90vh", marginTop: 65, backgroundColor: "#e6f7ff" }}
      >
        <Content style={{ padding: 10, margin: 10 }}>
          <Card style={{ backgroundColor: "#429629" }}>
            <Typography.Title style={{ textAlign: "center", color: "white" }}>
              REGISTER USER
            </Typography.Title>
          </Card>
        </Content>

        <Content style={{ padding: 10, margin: 10 }}>
          <Card
            title="FORM REGISTRATION NEW USER"
            bordered={true}
            hoverable={true}
            style={{
              fontSize: 50,
              textAlign: "center",
              fontWeight: "bold",
              color: "#0C60AA",
              justifyContent: "center",
            }}
          >
            <Form
              name="register-user"
              className="register-user"
              {...formItemLayout}
              form={form}
              onFinish={(e) => submitRegister(e)}
              onFinishFailed={onFinsihFailed}
            >
              <Form.Item
                name={"name"}
                label={"Name"}
                className="name"
                rules={[
                  {
                    type: "string",
                    message: "The input is not a valid name",
                  },
                  {
                    required: true,
                    message: "Please input your name",
                  },
                ]}
              >
                <Input
                  prefix={<SmileTwoTone style={{ paddingRight: "15px" }} />}
                />
              </Form.Item>

              <Form.Item
                name={"username"}
                label={"Username"}
                className="username"
                rules={[
                  {
                    type: "string",
                    message: "The input is not a valid username",
                  },
                  {
                    required: true,
                    message: "Please input your username",
                  },
                ]}
              >
                <Input
                  prefix={<SmileTwoTone style={{ paddingRight: "15px" }} />}
                />
              </Form.Item>

              <Form.Item
                name={"password"}
                label={"Password"}
                className="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockTwoTone style={{ paddingRight: "15px" }} />}
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="validatePassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The passwords are not match! Please input the correct one!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockTwoTone style={{ paddingRight: "15px" }} />}
                />
              </Form.Item>

              <Form.Item
                name="remember"
                className="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 4,
                  span: 24,
                }}
              >
                <Checkbox>
                  Remember me
                  {/* I have read the <a href="">agreement</a> */}
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="register-button"
                className="register-button"
                wrapperCol={{
                  offset: 4,
                  span: 24,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%" }}
                >
                  Register
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Content>
      </Layout>
    </>
  );
}
