// Import React's Components
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// EXCEL
import * as XLSX from "xlsx";

// CONTEXT
import { Context as WrapperContext } from "../../api/Context/Context";

// Import Ant Design Components
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Layout,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Loading Components
import LoadingComponents from "../../Components/Loading/Loading";
import { FileExcelOutlined, SyncOutlined } from "@ant-design/icons";

// CODE
export default function SuperadminDashboard() {
  // DATA COLUMNS
  const columns = [
    {
      title: "No",
      dataIndex: "",
      key: "",
      width: 100,
      // sorter: (a, b) => a.id - b.id,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Date",
      dataIndex: "datetime",
      key: "datetime",
      sorter: (a, b) => moment(a.datetime) - moment(b.datetime),
      render: (date, record) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  //   CONTEXT
  const { getDataMaster, getDataMasterDate } = useContext(WrapperContext);

  // STATE MANAGEMENT
  // Data
  const [dataMaster, setDataMaster] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  // Date
  const [date, setDate] = useState([]);
  // Device ID
  const [device, setDevice] = useState(null);
  const [devId, setDevId] = useState([]);
  // Location
  const [location, setLocation] = useState([]);
  // Error State
  const [visible, setVisible] = useState(false);
  // State Management Loading
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);

  // TOKEN
  const auth_token = sessionStorage.getItem("auth-token");
  const access_token = { "x-access-token": auth_token };

  // URL SOCKET IO
  const url = process.env.REACT_APP_AUTHURL;

  // Load Data
  const loadData = () => {
    setLoading(true);

    getDataMaster({
      dataMaster: "data-master",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("SUCCESS ==>>>>> ", response);

        let filtered = response.data.table;
        let arr = [];

        for (let i = 0; i < filtered.length; i++) {
          let newId = {
            ...filtered[i],
            id: parseInt(i),
            key: i + parseInt(filtered[i].shift),
          };
          arr.push(newId);
        }

        let optionFilter = response.data.device_id.map((value) => ({
          text: value,
          value: value,
        }));

        setDataTable(arr);
        // setDataTable(response.data.table);
        setDataMaster(arr);
        // setDataMaster(response.data.table);
        setDevId(optionFilter);
        setLocation(response.data.device_location);
        setLoading(false);
        setVisible(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        setVisible(true);
      },
    });
  };

  // Use Effect
  useEffect(() => {
    loadData();

    const interval = setInterval(() => {
      // loadData();
      setLoad(false);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // DEVICE FILTER
  const filterDevice = (value) => {
    console.log("Value >>>> ", value);
    const device = value;
    setLoading(true);

    let newData = dataMaster.filter((value) => {
      if (value.device_id == device) {
        return value;
      }
      setLoading(false);
    });

    setDataTable(newData);
    setDevice(value);
  };

  // DATETIME FILTER
  const filterDate = (value) => {
    console.log("Date >>> ", value);

    setDate(value);

    const start_date = value[0];
    const end_date = value[1];

    getDataMasterDate({
      dataMaster: "data-master",
      startDate: moment(start_date).format("YYYY-MM-DD"),
      endDate: moment(end_date).format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("SUCCESS ==>>>>> ", response);

        // AWAL NA URNG PAKE IEU KEUR MERE ID tapi jadi error
        let filtered = response.data;
        let arr = [];

        for (let i = 0; i < filtered.length; i++) {
          let newId = {
            ...filtered[i],
            id: parseInt(i + 1),
            key: i + parseInt(filtered[i].shift),
          };
          arr.push(newId);
        }

        setDataTable(response.data);
        // setDataTable(arr);
        setDataMaster(response.data);
        // setDataMaster(arr);
        setLoading(false);
        setVisible(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        setVisible(true);
      },
    });
  };

  // Download File as Excel
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Report File.xlsx");
  };

  // Loading
  if (load == true) {
    return <LoadingComponents />;
  }

  console.log("Data Table => ", dataTable);
  // console.log("Device => ", devId);
  // console.log("Location => ", location);

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          marginTop: 65,
          backgroundColor: "#e6f7ff",
        }}
      >
        <Content style={{ padding: 10, margin: 10 }}>
          <Card>
            <Typography.Title style={{ textAlign: "center" }}>
              {`DASHBOARD WRAPPING ALL TRANSACTION`}
            </Typography.Title>
          </Card>

          <br />

          {visible == true ? (
            <Alert
              message="ERROR!"
              description="The Server are offline, no data is received! Please, contact the Admin of the Server to reactivate the Server!"
              type="error"
              showIcon
            />
          ) : (
            <></>
          )}

          <Row
            // justify={"end"}
            style={{
              padding: 30,
              margin: "15px 0px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Col span={8}>
              <Typography.Title style={{ paddingBottom: 15 }}>
                Pick Date Range
              </Typography.Title>

              <DatePicker.RangePicker
                allowClear
                value={date}
                onChange={filterDate}
                className="date-range"
                style={{
                  width: 440,
                }}
              />
            </Col>

            <Col span={8}>
              <Typography.Title style={{ paddingBottom: 15 }}>
                Device ID
              </Typography.Title>

              <Select
                className="dev-id-selector"
                showSearch
                value={device}
                style={{
                  width: 440,
                }}
                options={devId}
                onChange={filterDevice}
              />
            </Col>

            <Col span={4}>
              <Typography.Title level={4} style={{ paddingBottom: 15 }}>
                Export Files
              </Typography.Title>

              <Button
                type="primary"
                danger={true}
                //   shape="round"
                icon={<FileExcelOutlined />}
                style={{
                  width: 250,
                  // margin: 30,
                  // fontSize: 40,
                }}
                onClick={() => downloadExcel(dataTable ? dataTable : [])}
              >
                <Typography.Text ellipsis style={{ color: "white" }}>
                  Export to Excel Files
                </Typography.Text>
              </Button>
            </Col>

            <Col span={4}>
              <Row justify={"start"}>
                <Typography.Title level={4} style={{ paddingBottom: 15 }}>
                  Refresh Filter
                </Typography.Title>
              </Row>

              <Row justify={"start"}>
                <Button
                  type="primary"
                  className="refresh-btn"
                  icon={<SyncOutlined />}
                  onClick={() => {
                    loadData();
                    setDate([]);
                    setDevice(null);
                  }}
                  style={{
                    width: 250,
                    // margin: 30,
                  }}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>
        </Content>

        <Content
          style={{
            padding: 10,
            margin: "10px 10px 30px",
            // backgroundColor: "#FFFFFF"
          }}
        >
          <Table
            className="table-superadmin"
            name="table-superadmin"
            // key="table-superadmin" LAIN IEU, IEU MAH KEY TABLE, NU MASALAH NA ROWKEY TABLE, ROW TABLE NA
            key={dataTable.map((dt, ind) => {
              return ind;
            })}
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataTable}
            pagination={{
              position: ["topRight"],
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            rowKey={dataTable.map((val, index) => {
              //GES AMAN CAN? ANGGER
              //ETA ERROR TI NUMANA?
              // TIDIEU TINA TABLE
              // TABLE ANTD KUDU PAKE ROWKEY MUN TE KE DATA NA ABSURD, GEUS DI REFRESH SI DATA NA DOUBLE GARA2 GA PUNYA ID/KEY
              // console.log("INDEX>>>>>>>>>>" + index);
              //ETA KAN GES DIBERE INDEX SI ROW KEYNA
              // IYA TAPI ANGGER NING AYA WARNING/ERROR, KELA
              return (Math.random() * 16) | 0;
            })}
            // rowKey={(record, index) => {
            //   // IEU MUN PAKE INDEX
            //   // iye indexna ngaco
            //   console.log("INNDEX>>>>", index);
            //   // return index;
            //   return record.id ? record.id : record.key;
            // }}
          />
        </Content>
      </Layout>
    </>
  );
}
