// Import React's Components
import React, { useEffect, useState } from "react";

// Import Antd CSS
import "antd/dist/antd.css";
import "./App.css";

// Import Components
import NavigationPane from "./Components/Routes/Router";

// Import Context Provider
import { Provider as WrapperProvider } from "./api/Context/Context";
import { Spin } from "antd";

function App() {
  // Document Title
  useEffect(() => {
    document.title = "Wrap Dashboard";
  }, []);

  return (
    <>
      <WrapperProvider>
        <NavigationPane />
      </WrapperProvider>
    </>
  );
}

export default App;
