// Import React's Components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Import Ant Design's Components
import {
  Layout,
  Drawer,
  Button,
  Row,
  Typography,
  Divider,
  Space,
  Avatar,
  Col,
} from "antd";
import { Header } from "antd/lib/layout/layout";
import {
  AppstoreOutlined,
  AppstoreTwoTone,
  HistoryOutlined,
  IdcardTwoTone,
  LoginOutlined,
  LogoutOutlined,
  MenuOutlined,
  ProfileOutlined,
  UserOutlined,
  WarningOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import HeaderComponents from "../Layout/Header";
import LoadingComponents from "../Loading/Loading";

// Create Styles
const styles = {
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  fontWeight: "bolder",
  fontSize: "large",
};

// Const Styles
const iconStyles = {
  marginRight: 20,
  marginTop: "5px",
};

// Drawer Code
export default function SideDrawer() {
  // STATE MANAGEMENT
  // Superadmin
  const [superadmin, setSuperadmin] = useState(false);
  // Drawer
  const [visible, setVisible] = useState(false);

  // Show Drawer Function
  const showDrawer = () => {
    setVisible(true);
  };

  // Close Drawer Function
  const onClose = () => {
    setVisible(false);
  };

  // Get Token User
  const token = sessionStorage.getItem("admin-roles");
  const company_name = sessionStorage.getItem("company_name");

  // USE EFFECT
  useEffect(() => {
    if (company_name == "superadmin") {
      setSuperadmin(true);
    } else {
      setSuperadmin(false);
    }
  }, [superadmin]);

  // console.log(token);

  return (
    <>
      <Layout>
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
          }}
        >
          <Row justify={"space-between"}>
            <Col span={4}>
              <Button
                icon={<MenuOutlined />}
                onClick={showDrawer}
                ghost
                style={{
                  marginTop: "15px",
                  marginRight: "20px",
                  marginLeft: "-25px",
                }}
              />
            </Col>

            <Col span={20}>
              <HeaderComponents />
            </Col>
          </Row>

          <Drawer
            title="Dashboard"
            placement="left"
            onClose={onClose}
            open={visible}
          >
            {superadmin == true ? (
              <Space>
                <Link
                  to={"/dashboard-superadmin"}
                  style={styles}
                  onClick={() => setVisible(false)}
                >
                  <AppstoreTwoTone style={iconStyles} />
                  <Typography>Dashboard</Typography>
                </Link>
              </Space>
            ) : (
              <>
                <Space>
                  <Link
                    to={"/dashboard"}
                    style={styles}
                    onClick={() => setVisible(false)}
                  >
                    <AppstoreTwoTone style={iconStyles} />
                    <Typography>Dashboard</Typography>
                  </Link>
                </Space>

                <Divider />

                <Space>
                  <Link
                    to={"/history"}
                    style={styles}
                    onClick={() => setVisible(false)}
                  >
                    <HistoryOutlined style={iconStyles} />
                    <Typography>History</Typography>
                  </Link>
                </Space>
                <Divider />

                {token == "true" ? (
                  <>
                    <Space>
                      <Link
                        to={"/registration-card"}
                        style={styles}
                        onClick={() => setVisible(false)}
                      >
                        <IdcardTwoTone style={iconStyles} />
                        <Typography>Register Card</Typography>
                      </Link>
                    </Space>
                    <Divider />
                    <Space>
                      <Link
                        to={"/register-user"}
                        style={styles}
                        onClick={() => setVisible(false)}
                      >
                        <UserOutlined style={iconStyles} />
                        <Typography>Register User</Typography>
                      </Link>
                    </Space>
                    <Divider />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Drawer>
        </Header>
      </Layout>
    </>
  );
}
