// Import React's Components
import React, { useState } from "react";

// Import Ant Design's Components
import { Dropdown, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";

// Code
export default function SelectShift({ getShift, onSet }) {
  // State Managements
  const [loadings, setLoadings] = useState([]);
  const [value, setValue] = useState();

  // Value Function

  return (
    <>
      <Select
        // labelInValue
        defaultValue={{
          value: 1,
          label: "Shift 1",
        }}
        style={{
          width: "100%",
        }}
        value={value}
        onChange={(val) => {
          setValue(val);
          //   console.log(val);

          getShift(val);
          if (onSet) onSet(val);
        }}
        options={[
          {
            value: 0,
            label: "Untapped",
          },
          {
            value: 1,
            label: "Shift 1",
          },
          {
            value: 2,
            label: "Shift 2",
          },
          {
            value: 3,
            label: "Shift 3",
          },
        ]}
      />
    </>
  );
}
