// Import React's Components
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Import Ant Design Components
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import "../../App.css";

// Import Ant Design Icons
import { LockTwoTone, SmileTwoTone, UserOutlined } from "@ant-design/icons";
import {
  showFailedLogin,
  showSuccessLogin,
} from "../../Components/Notifications/Notifications";
import LoadingComponents from "../../Components/Loading/Loading";

// Layout Form
const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: "100%",
  },
};

// Code
export default function LoginPage(props) {
  // State Management Email & Password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Get Token and URL
  const url = process.env.REACT_APP_AUTHURL;

  // Use Navigate
  const navigate = useNavigate();

  // Handle Input Email
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // Handle Input Password
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // Handle Login
  const handleLogin = (e) => {
    // e.preventDefault();
    // console.log(e);
    // console.log(props);

    // }
    axios
      .post(`${url}api/v1/login-user`, {
        username: email,
        password: password,
      })
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          let accessToken = response.data;
          sessionStorage.setItem("auth-token", accessToken.token);
          sessionStorage.setItem("company_name", accessToken.company_name);
          sessionStorage.setItem("admin-roles", accessToken.admin);
          showSuccessLogin(response);

          setTimeout(() => {
            window.location.reload(false);
          }, 500);
        } else {
          showFailedLogin(response);
        }
      })
      .catch((error) => {
        // console.log(error);
        showFailedLogin(error);
      })
      .finally((final) => {
        // console.log(final);
        navigate("/");
        // if (accessToken.token) {
        //   navigate("/");
        // } else {
        //   showFailedLogin();
        //   navigate("/login");
        // }
        // showSuccessLogin();
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 500);
      });

    // let admin = {
    //   email: "admin",
    //   password: "wrapadmin",
    // };

    // let user = {
    //   email: "user",
    //   password: "wrapuser",
    // };

    // if (email == admin.email && password == admin.password) {
    //   const token = "4DM1NS3S";
    //   sessionStorage.setItem("auth-token", token);
    //   navigate("/");
    //   setTimeout(() => {
    //     window.location.reload(false);
    //   }, 500);
    // } else if (email == user.email && password == user.password) {
    //   const token = "U53RS3S";
    //   sessionStorage.setItem("auth-token", token);
    //   navigate("/");
    //   setTimeout(() => {
    //     window.location.reload(false);
    //   }, 500);
    // } else {
    //   alert("User not found\nCheck your email and password again");
    // }
  };

  return (
    <>
      <Layout>
        <Content>
          <Row
            align="middle"
            justify="center"
            style={{ minHeight: "100vh", backgroundColor: "#91d5ff" }}
          >
            <Col>
              <Card
                style={{
                  width: "100%",
                  placeContent: "center",
                  alignSelf: "center",
                  borderRadius: "10px",
                }}
                type="flex"
                align="middle"
              >
                <Space
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "baseline",
                    paddingBottom: "10px",
                  }}
                >
                  <UserOutlined style={{ fontSize: 20 }} />
                  <Typography.Title level={4}>LOGIN</Typography.Title>
                </Space>

                {/* <Image
                  src="logo.jpg"
                  width={150}
                  height={100}
                  preview={false}
                  style={{ paddingBottom: "25px" }}
                /> */}

                <Form
                  name="basic"
                  {...formItemLayout}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleLogin}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input
                      value={email}
                      onChange={handleEmail}
                      prefix={<SmileTwoTone />}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      value={password}
                      onChange={handlePassword}
                      prefix={<LockTwoTone />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                      offset: 0,
                      span: 25,
                    }}
                  >
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 50,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="primary"
                      size="large"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
