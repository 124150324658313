// Import React Components
import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

// Import Ant Design's Components
import { Alert, Card, Col, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Context
import { Context as WrapperContext } from "../../api/Context/Context";

// Import Axios
import axios from "axios";

const { Title } = Typography;

// Create Styles
const styles = {
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "bold",
  color: "#096dd9",
  height: "90%",
  // margin: 10,
};

// Code
export default function CardWrapping({ dataCard }) {
  // STATE MANAGEMENT
  const { state, getDataTable } = useContext(WrapperContext);
  const [loading, setLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [dataCurrent, setDataCurrent] = useState(null);
  const [shift, setShift] = useState(null);
  const [visible, setVisible] = useState(false);
  // Count Last Shift
  const [lastCount, setLastCount] = useState(null);

  // URL SOCKET IO
  // const urlIO = process.env.REACT_APP_AUTHURL;
  const urlIO = process.env.REACT_APP_BASEIO;
  const socket = io(urlIO);
  // console.log(urlIO);

  // Get Data Current
  const loadCurrent = async () => {
    getDataTable({
      dataTable: "data",
      onAwait: () => "Wait for Data",
      onSuccess: (current) => {
        // console.log("SUCCESS", current.data);
        // console.log(state);
        setDataCurrent(current.data.current);
        setShift(current.data.current.shift);
        setLastCount(current.data.data);
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // console.log(state);

  // Use Effect
  useEffect(() => {
    // loadCurrent();
    if (dataCard) {
      setDataCurrent(dataCard);
      setShift(dataCard.shift);
      setLastCount(dataCard.lastCount);
    } else {
      setDataCurrent([]);
    }
    // setLoading(true);
    // const interval = setInterval(() => {
    //   loadCurrent();
    // }, 500);
    // return () => {
    //   clearInterval(interval);
    // };
  }, [shift, dataCard]);

  useEffect(() => {
    // Connected
    socket.on("connnect", (response) => {
      console.log("connected to server", response);
    });

    // Error Not Connected
    socket.io.on("error", (error) => {
      console.log("Error connecting to server", error);
      // setVisible(true);
    });

    socket.on("pong", (data) => {
      // loadCurrent();
      console.log("PONG DATA: ", data);
    });
  }, []);

  console.log("Ini isi Current: ", dataCurrent);

  return (
    <>
      <Layout style={{ backgroundColor: "#e6f7ff" }}>
        <Content>
          {visible && (
            <Alert
              message="ERROR!"
              description="The Server are offline, no data is received! Please, contact the Admin of the Server to reactivate the Server!"
              type="error"
              showIcon
            />
          )}

          <Row
            justify="space-between"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            style={{
              marginTop: 25,
              height: "100%",
              // backgroundColor: "#e6f7ff",
            }}
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
              <Card
                title="USER INFO"
                bordered={true}
                style={styles}
                hoverable={true}
              >
                <Row
                  justify="space-around"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Space className="space-card">
                    <Col>
                      <Typography.Text>ID Card</Typography.Text>
                      <p>
                        {dataCurrent?.card_id ? dataCurrent.card_id : "xxxxxxx"}
                      </p>
                    </Col>

                    <Col>
                      <Typography.Text>Name</Typography.Text>
                      <p>
                        {dataCurrent?.operator_name
                          ? dataCurrent.operator_name
                          : "Waiting..."}
                      </p>
                    </Col>
                  </Space>
                </Row>

                <Typography.Text>Location</Typography.Text>
                <p>
                  {dataCurrent?.device_location
                    ? dataCurrent.device_location
                    : "Unknown Locations"}
                </p>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4}>
              <Card
                title="CURRENT COUNT"
                bordered={true}
                style={{
                  fontSize: 82,
                  // margin: 10,
                  height: "90%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                hoverable={true}
              >
                <Typography style={{ color: "#096dd9" }}>
                  {dataCurrent?.status_device == "Online"
                    ? dataCurrent.wrap_count
                    : 0}
                </Typography>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
              <Card
                title="PLASTIC WRAP LENGTH"
                bordered={true}
                style={{
                  fontSize: 82,
                  // margin: 10,
                  height: "90%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                hoverable={true}
              >
                <Typography style={{ color: "#096dd9", fontSize: 60 }}>
                  {dataCurrent?.status_device == "Online"
                    ? `${(
                        parseInt(dataCurrent?.wrap_length || 0) * 0.2
                      ).toFixed(2)} m`
                    : "0 m"}
                </Typography>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4}>
              <Card
                title="CURRENT SHIFT"
                bordered={true}
                style={{
                  fontSize: 82,
                  // margin: 10,
                  height: "90%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                hoverable={true}
              >
                <Typography style={{ color: "#096dd9" }}>
                  {/* <Typography.Text>Shift</Typography.Text> */}
                  {/* <p>{dataCurrent ? dataCurrent.shift : "No Shift"}</p> */}
                  <p>{shift ? shift : "No Shift"}</p>
                  {/* <p>1</p> */}
                </Typography>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4}>
              <Card
                title="LAST SHIFT COUNT"
                bordered={true}
                style={{
                  fontSize: 82,
                  // margin: 10,
                  height: "90%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                hoverable={true}
              >
                <Typography style={{ color: "#096dd9" }}>
                  {/* <Typography.Text>Shift</Typography.Text> */}
                  <p>{dataCurrent?.lastCount ? dataCurrent.lastCount : 0}</p>
                  {/* <p>1</p> */}
                </Typography>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
